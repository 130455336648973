<template>
	<form class="form form--tools form-tools" @submit.prevent.stop="">
		<div class="form__loader" v-if="isLoading">
			<loading-spinner />
		</div>

		<div class="form-section">
			<div class="form-row form-row--col-4 steps-row">
				<div>
					<h3>Step 1</h3>
					<h4>Contacts List</h4>
					<file-field class="form__field" @change="handleContacts" label="Select File (.csv)" :key="fileKey" :preview="true" />
				</div>
				<div>
					<h3>Step 2</h3>
					<h4>Swap Analyzer</h4>
					<select-field :label="`Valuation Date ${datesLoading ? '(loading...)' : ''}`" v-model="fields.valuationDate" class="mdc-select--basic" :options="valuationDates" :disabled="datesLoading" />
					<!-- <file-field class="form__field" @change="handleSwap" :disabled="!Object.keys(marks).length" label="Select File (.csv)" :preview="true" /> -->
				</div>
				<div>
					<h3>Step 3</h3>
					<h4>
						<switch-field label="Send as Test" v-model="fields.test"></switch-field>
					</h4>
					<text-field label="Emails (comma separated)" v-model="fields.testEmails" :disabled="!fields.test" />
				</div>
				<div>
					<h3>Step 4</h3>
					<h4>Confirm Send</h4>
					<button class="button button--unelevated button--secondary" :disabled="(!Object.keys(marks).length || !contactFile || !trades.length || !!results.length || !fields.valuationDate)" @click="sendMail(fields)">Send Email</button><br />
					<small v-if="fields.valuationDate">Valuation Date: {{ fields.valuationDate | date }}</small>
					<template v-if="results.length">
						<br />
						<a class="button" @click="reset">Reset <ion-icon name="refresh"></ion-icon></a>
					</template>
				</div>
			</div>
		</div>

		<div class="form-section" v-if="sendResults.length">
			<h4>Results</h4>
			<div class="form-tools__results" v-html="sendResults"></div>
		</div>

		<tab-bar
			:items="statementTypes">
			<template slot-scope="{ item, idx }" slot="item">
				<div
					class="tab-bar__item a"
					:class="{'tab-bar__item--active': activeStatement == item.link}"
					:id="`type_tab_${idx}`"
					@click="activeStatement = item.link"
					>
					{{ item.label }} ({{ counts[item.link] }})
				</div>
			</template>
		</tab-bar>

		<table-list
			:items="filteredMarks"
			>
			<template slot="header">
				<span class="tools-header-item">
					Custom ID
				</span>
				<span class="tools-header-item">
					State
				</span>
				<span class="tools-header-item">
					Type
				</span>
				<span class="tools-header-item">
					Contacts
				</span>
			</template>

			<template slot="item" slot-scope="{ item, idx }">
				<span class="tools-item" :class="{'tools-item--data': item.trades.length}">
					{{ item.customId }} ({{ item.trades.length }})
				</span>
				<span class="tools-item" :class="{'tools-item--data': item.trades.length}">
					{{ item.state }}
				</span>
				<span class="tools-item" :class="{'tools-item--data': item.trades.length}">
					{{ item.statementType }}
				</span>
				<span class="tools-item">
					{{ item.contacts | contacts }}
				</span>
				<span class="tools-item tools-item--detail" :class="`tools-item--detail-${item.statementType}`">

					<table-list
						:items="item.trades"
						class="table-list--details"
						:class="`table-list--details-${item.statementType}`"
						v-if="item.trades.length"
						>
						<template slot="header">
							<span class="details-header-item" v-if="item.statementType != 'swap'">
								Counterparty
							</span>
							<span class="details-header-item">
								Trade ID
							</span>
							<span class="details-header-item">
								Exposure
							</span>
							<span class="details-header-item">
								Exposure Override
							</span>
							<span class="details-header-item">
								DV01
							</span>
							<span class="details-header-item" v-if="item.statementType != 'last30'">
								Effective Date
							</span>
							<span class="details-header-item" v-if="item.statementType != 'last30'">
								Maturity Date
							</span>
							<span class="details-header-item" v-if="item.statementType == 'last30'">
								Trade Date
							</span>
							<span class="details-header-item" v-if="item.statementType == 'swap'">
								Product Type
							</span>
							<span class="details-header-item" v-if="item.statementType == 'swap' || item.statementType == 'bank'">
								Notional
							</span>
							<span class="details-header-item" v-if="item.statementType == 'swap'">
								Index
							</span>
							<span class="details-header-item" v-if="item.statementType == 'swap'">
								Accrual
							</span>
							<span class="details-header-item" v-if="item.statementType == 'swap'">
								Valuation
							</span>
							<span class="details-header-item" v-if="item.statementType == 'last30'">
								Bank
							</span>
						</template>
						<template slot="item" slot-scope="{ item: detail, idx }">
							<span class="details-item" v-if="item.statementType != 'swap'">
								{{ detail.counterparty }}
							</span>
							<span class="details-item">
								{{ detail.tradeId }}
							</span>
							<span class="details-item">
								{{ detail.swMarketVal | currency }}
							</span>
							<span class="details-item" :key="`override_${item.statementType}_${idx}`">
								<template v-if="detail.edit">
									<input type="tel" v-model="detail.swMarketValOverride" size="12"  @keypress.13.prevent="setOverride($event, item.trades, idx)" />
								</template>
								<template v-else>
									<span v-if="detail.swMarketValOverride" @click.prevent="toggleEdit(item.trades, idx)">{{ detail.swMarketValOverride | currency }}</span>
									&nbsp;<ion-icon name="create" class="pointer" @click.prevent="toggleEdit(item.trades, idx)" />
								</template>
							</span>
							<span class="details-item">
								{{ detail.dv01 | currency }}
							</span>
							<span class="details-item" v-if="item.statementType != 'last30'">
								{{ detail.dateEffective | date }}
							</span>
							<span class="details-item" v-if="item.statementType != 'last30'">
								{{ detail.dateMaturity | date }}
							</span>
							<span class="details-item" v-if="item.statementType == 'last30'">
								{{ detail.tradeIdDate | date }}
							</span>
							<span class="details-item" v-if="item.statementType == 'swap'">
								{{ detail.productType }}
							</span>
							<span class="details-item" v-if="item.statementType == 'swap' || item.statementType == 'bank'">
								{{ detail.outstandingNotional | currency(false, 0) }}
							</span>
							<span class="details-item" v-if="item.statementType == 'swap'">
								{{ detail.underlyingIndex }}
							</span>
							<span class="details-item" v-if="item.statementType == 'swap'">
								{{ detail.netAccrual | currency }}
							</span>
							<span class="details-item" v-if="item.statementType == 'swap'">
								{{ detail.swMarketVal | currency }}
							</span>
							<span class="details-item" v-if="item.statementType == 'last30'">
								{{ detail.associatedBank }}
							</span>
						</template>
					</table-list>

				</span>
			</template>
		</table-list>
	</form>
</template>

<script>
import { camelCase, pick } from 'lodash'
import { parse } from 'papaparse'
import { parse as dateParse, parseISO, format, getMonth, differenceInDays } from 'date-fns'
import { toNumber, toPrecision } from '@/lib/utils'
import { v4 } from 'uuid'
import Vue from 'vue'

import FileField from '@/components/FileField'
import LoadingSpinner from '@/components/LoadingSpinner'
import SelectField from '@/components/SelectField'
import SwitchField from '@/components/SwitchField'
import TabBar from '@/components/TabBar'
import TableList from '@/components/TableList'
import TextField from '@/components/TextField'
import UploadField from '@/components/UploadField'

export default {
	name: 'FormToolsMarket',
	components: {
		FileField,
		LoadingSpinner,
		SelectField,
		SwitchField,
		TabBar,
		TableList,
		TextField,
		UploadField,
	},
	data: () => ({
		isLoading: false,
		contacts: [],
		swaps: {},
		fields: {
			test: true,
		},
		marks: {},
		marksByType: {},
		tradeItems: {},
		tradesByType: {},
		fileKey: v4(),
		activeStatement: 'borrower',
		statementTypes: [
			{
				label: 'Borrower',
				link: `borrower`,
				count: 0,
			},
			{
				label: 'Bank',
				link: `bank`,
				count: 0,
			},
			{
				label: 'Swap',
				link: `swap`,
				count: 0,
			},
			{
				label: 'Last 30 days',
				link: `last30`,
				count: 0,
			},
		],
		last30: [],
		results: [],
		contactFile: null,
		swapFile: null,
		datesLoading: false,
		search: {},
		toEdit: {
			last30: [],
		},
	}),
	computed: {
		filteredMarks() {
			return Object.fromEntries(
				Object.entries(this.tradeItems).filter(([key, value]) => {
					return value.statementType == this.activeStatement
				}).map(([key, value]) => {
					return [key.replace(`_${value.statementType}`,''), value]
				})
			)

			let filtered = {}


			for (const k in this.tradeItems) {
				if (this.tradeItems[k].statementType == this.activeStatement) {
					let _key = k.replace(`_${this.tradeItems[k].statementType}`,'')
					filtered[_key] = this.tradeItems[k]
				}
			}

			let newFilter = Object.fromEntries(
				Object.entries(this.tradeItems).filter(([key, value]) => {
					console.log({key, value})
					return value.statementType == this.activeStatement
				}).map(([key, value]) => {
					return [key.replace(`_${value.statementType}`,''), value]
				})
			)

			// filtered = this.tradesByType[this.activeStatement] || {}
			let oFiltered = this.tradesByType[this.activeStatement] || {}
			if (this.activeStatement == 'last30') {
				filtered['last30'] = {
					statementType: 'last30',
					trades: this.last30,
				}
			}

			return filtered
		},
		bvalData() {
			return this.$store.getters['tools/bvalData']
		},
		valuationDate() {
			return this.fields.valuationDate
		},
		valuationDates() {
			if (this.bvalData.dates) {
				return this.bvalData.dates
			}

			return []
		},
		trades() {
			return this.bvalData.trades
		},
		sendResults() {
			return this.results.join(`\n`)
		},
		counts() {
			let counts = {}
			for (const type of this.statementTypes) {
				counts[type.link] = Object.values(this.tradeItems).filter(f => f.trades.length && f.statementType == type.link).length
				// counts[type.link] = Object.values(this.tradesByType[type.link] || {}).filter(f => f.trades.length > 0).length
			}
			// counts['last30'] = this.last30.length

			return counts
		}
	},
	filters: {
		contacts(data) {
			return (data || []).join(`\n`)
		},
	},
	mounted() {
		this.datesLoading = true
		this.$store.dispatch('tools/queryBvalDates')
			.finally(() => {
				this.datesLoading = false
			})
	},
	methods: {
		reset() {
			this.results = []
			this.contactFile = undefined
			this.marks = {}
			this.marksByType = {}
			this.tradeItems = {}
			this.tradesByType = {}
			this.fileKey = v4()
			this.fields = {
				test: true,
			}
		},
		toggleEdit(trades, idx){
			this.$set(trades[idx], 'edit', true)
		},
		setOverride(evt, trades, idx) {
			this.$set(trades[idx], 'swMarketValOverride', evt.target.value)
			this.$set(trades[idx], 'edit', false)
		},
		async queryData() {
			this.isLoading = true
			const { p, limit } = this.$route.query
			try {
				await this.$store.dispatch(`tools/queryBvalData`, { p, limit })
			} catch (error) {
				this.$notice(`ERROR: ${error}`)
			} finally {
				this.isLoading = false
			}
		},
		sendMail(fields) {
			let marks = Object.keys(this.tradeItems)
				.filter(k => this.tradeItems[k].trades.length && this.tradeItems[k].contacts?.length)
				.reduce((obj, key) => {
					obj[key] = this.tradeItems[key]
					return obj
				}, {})

			marks = Object.fromEntries(
				Object.entries(marks).map(([key, value]) => {
					value.trades = value.trades.map(t => {
						t.swMarketVal = t.swMarketValOverride ? Number(t.swMarketValOverride) : t.swMarketVal
						return t
					})
					return [key, value]
				})
			)

			const contactsCount = this.fields.test ? this.fields.testEmails.split(',').length : Object.values(marks).reduce((a, b) => a + b.contacts?.length, 0)

			this.$confirm(`This will send the ${this.fields.test ? '(TEST)' : ''} Mark to Market email to ${contactsCount} contacts. Are you ready to send?`, () => {
				this.isLoading = true
				this.$store.dispatch(`tools/sendMarkToMarket`, { test: fields.test, testEmails: fields.testEmails, data: marks, valuationDate: this.valuationDate })
					.then((res) => {
						this.results = res
					})
					.catch((error) => {
						this.$notice(`ERROR: ${error.message || error}`)
					})
					.finally(() => {
						this.isLoading = false
					})

			}, {
				acceptLabel: `Yes, Send`,
				title: `Send Mark to Market`,
			})
		},
		handleContacts(files) {
			if (this.isLoading) return
			const reader = new FileReader()

			if (files[0]) {
				const file = files[0]
				reader.readAsText(file)
				this.contactFile = file
			} else {
				this.$set(this, 'marks', [])
				return this.contactFile = null
			}

			this.isLoading = true
			reader.onload = (file) => {
				this.isLoading = true
				try {
					parse(file.target.result, {
						skipEmptyLines: true,
						step: (row) => {
							if (row.data[0] && row.data[3] ) {
								const ms = row.data[3].split(',')
								const data = {
									customId: row.data[1],
									state: row.data[2],
									statementType: row.data[4].toLowerCase(),
									statement: row.data[4],
									contacts: [],
									trades: [],
									ms,
								}

								for (let i = 5; i < row.data.length; i++) {
									if ((i % 2) == 0 && row.data[i]) {
										data.contacts.push(row.data[i])
									}
								}

								const key = [data.customId, data.statementType].join(`_`)
								this.$set(this.marks, key, data)
								if (!this.marksByType[data.statementType]) {
									this.marksByType[data.statementType] = {}
								}
								this.$set(this.marksByType[data.statementType], data.customId, data)
							}
						},
						complete: () => {
							this.isLoading = false
						},
					})
				} catch (error) {
					this.isLoading = false
					this.$notice(`ERROR: ${error.message || error}`)
				}
				this.isLoading = false
			}
		},
		handleTrades() {
			let month = (new Date(this.fields.valuationDate)).getMonth() + 1 + ''
			let valuation = (new Date(this.fields.valuationDate))
			this.tradeItems = {}
			this.tradesByType = {}
			this.marks['last30'] = this.marks['last30'] ? this.marks['last30'] : Vue.observable({
				statementType: 'last30',
				ms: [month],
				trades: [],
			})
			for (const customId in this.marks) {
				this.marks[customId].trades = []
			}

			for (const t of this.trades) {
				let keys = [t.borrower, t.bank2]
				for (const key of keys) {

					let item = {
						key: t.borrower,
						tradeId: t.swCounterParty,
						counterparty: t.borrower,
						productType: t.swType,
						dateEffective: t.effective,
						dateMaturity: t.maturity,
						outstandingNotional: Math.abs(t.swDealNotlAmt),
						swMarketVal: 0 - t.swMarketVal,
						index: t.payFltRateIdx,
						payCoupon: t.swPayCpn * 100,
						recCoupon: t.swRecCpn * 100,
						rateType: t.swRecCpnType,
						accrual: 0 - (t.dv01 || t.swEqvBpv),
						netAccrual: 0 - t.swNetAccInt,
						associatedBank: t.customer,
						dv01: t.dv01 || t.swEqvBpv,
					}
					try {
						let idDate = item.tradeId.match(/(\d{4})_(\d{4})/)
						item.tradeIdDate = dateParse(idDate[0], 'yyyy_MMdd', new Date())
					} catch (error) {

					}
					item.underlyingIndex = `${item.index} vs ${toPrecision(item.recCoupon, 2)}% ${item.rateType}`

					for (const type in this.marksByType) {
						if (this.marksByType[type][key]) {
							item.index = item.index.replace(/US0001M(\s+)Index/i, `1M LIBOR`)
							item.index = item.index.replace(/US0003M(\s+)Index/i, `3M LIBOR`)
							item.index = item.index.replace(/SOFRRATE(\s+)Index/i, `SOFR`)
							item.index = item.index.replace(/Prime(\s+)Index/i, `Prime`)
							if ( !item.tradeId.match(/(.*)b$/i) ) {
								const exists = this.marksByType[type][key].trades.find(t => t.tradeId == item.tradeId)
								if (!exists) {
									this.marksByType[type][key].trades.push(item)
								}
							}

						}
					}

					if (this.marks[key]) {
						item.index = item.index.replace(/US0001M(\s+)Index/i, `1M LIBOR`)
						item.index = item.index.replace(/US0003M(\s+)Index/i, `3M LIBOR`)
						item.index = item.index.replace(/SOFRRATE(\s+)Index/i, `SOFR`)
						item.index = item.index.replace(/Prime(\s+)Index/i, `Prime`)
						if ( !item.tradeId.match(/(.*)b$/i) ) {
							this.marks[key].trades = [...(new Set([...this.marks[key].trades, ...[item]]))]
						}
					}

					if ( !item.tradeId.match(/(.*)b$/i) ) {
						if (item.tradeIdDate) {
							let diff = differenceInDays(valuation, item.tradeIdDate)
							if (diff <= 30 ) {
								let idx = this.marks['last30'].trades.findIndex(l => l.tradeId == item.tradeId)
								if (idx < 0) {
									this.marks['last30'].trades = [...(new Set([...this.marks['last30'].trades, ...[item]]))]
								// 	// this.marks['last30'] = this.marks['last30'] ? this.marks['last30'] : {
								// 	// 	statementType: 'last30',
								// 	// 	ms: [month],
								// 	// 	trades: [],
								// 	// }
								// 	// this.last30 = [...new Set([...this.last30, ...[item]])]
								}
							}
						}
					}
				}
			}

			for (const customId in this.marks) {
				const { ms } = this.marks[customId]
				if (ms.includes(month)) {
					this.tradeItems[customId] = this.marks[customId]
					this.tradeItems[customId].uuid = v4()
				}
			}

			for (const type in this.marksByType) {
				for (const key in this.marksByType[type]) {
					const { ms } = this.marksByType[type][key]
					if (ms.includes(month)) {
						this.tradesByType[type] = this.tradesByType[type] || {}
						this.tradesByType[type][key] = this.marksByType[type][key]
					}
				}
			}
		}
	},
	watch: {
		valuationDate (newVal,) {
			if (!newVal) return
			const fields = ['valuationDate']
			const values = [newVal]
			this.isLoading = true
			this.$store.dispatch(`tools/queryBvalData`, { fields, values, p: 1, limit: 10000, })
				.then(() => {
					this.isLoading = false
					this.handleTrades()
				})
		},
		trades () {
			return this.handleTrades()
		},
		'fields.test' (newVal, oldVal) {
			if (newVal != oldVal) {
				this.results = []
			}
		},
	},
	metaInfo: () => ({
		title: `Mark to Market`,
	}),
}
</script>

<style scoped lang="scss">
.form-tools {

	&__results {
		background-color: rgba(0,0, 0, 0.85);
		color: white;
		font-family: monospace, monospace;
		font-size: 12px;
		line-height: 1.5;
		max-height: 250px;
		overflow-y: auto;
		padding: 1rem 2rem;
		border-radius: 5px;
		white-space: pre-line;
	}


	::v-deep .table-list {
		&__item,
		&__header {
			align-items: center;
			border-bottom: 1px solid modules.color_('background', 'medium');
			display: grid;
			grid-template-columns: 1fr 150px 150px 1fr;

			@include modules.media-query('phone') {
				grid-template-columns: 1fr 1fr;
			}

			.hover {
				visibility: hidden;
			}

			&:hover {
				.hover {
					visibility: visible;
				}
			}
		}

		&__item {
			align-items: end;
		}
	}

	::v-deep .table-list--details {
		border: 1px solid modules.color_('background', 'medium');
		margin: 0 0.5rem 0.5rem 0.5rem;
		max-height: 240px;
		overflow-y: auto;

		&-swap {
			background-color: modules.color_('background');
			.table-list__header,
			.table-list__item {
				grid-template-columns: repeat(11, minmax(120px, auto)) !important;
			}
		}

		&-last30 {
			.table-list__header,
			.table-list__item {
				grid-template-columns: repeat(7, minmax(120px, 1fr)) !important;
			}
		}

		.table-list__header {
			padding: 0.5rem 1rem;
		}

		.table-list__item {
			align-items: flex-start;
			background-color: modules.color_('background');
			font-weight: normal;
			padding: 0.5rem 1rem;

			&:hover {
				background-color: rgba(246, 246, 246, 1);
			}

			&:last-of-type {
				border-bottom: 0;
			}
		}

		.table-list__item,
		.table-list__header {
			border-bottom: 1px solid modules.color_('background', 'light');
			display: grid;
			grid-template-columns: 240px repeat(7, minmax(100px, 1fr));
		}
	}

	.tools-header-item {
		@include modules.gutter('padding');
		@include modules.gutter('padding-bottom', 0.5);
		font-weight: bold;
	}

	.details-header-item {
		font-size: 12px;
		text-transform: uppercase;
		letter-spacing: 0.1px;
		font-weight: 500
	}

	.tools-item {
		padding-top: 0.5rem;
		@include modules.gutter('padding-left');
		@include modules.gutter('padding-right');
		white-space: pre-line;

		&:last-of-type {
			@include modules.gutter('padding-left', 0.25);
			@include modules.gutter('padding-right', 0.25);
		}

		&--detail {
			grid-column: 1 / 5;
		}

		&--data {
			font-weight: 500;
		}
	}

	.details-item {
		font-family: monospace, monospace;
		font-size: 12px;
	}
}
</style>
